
      try {
        window.BUI_BUILD_VERSION = window.BUI_BUILD_VERSION || {};
        window.BUI_BUILD_VERSION["eimos"] = window.BUI_BUILD_VERSION["eimos"] || {};
        window.BUI_BUILD_VERSION["eimos"].commitId = "42a70ee";
        window.BUI_BUILD_VERSION["eimos"].branchName = "HEAD";
        window.BUI_BUILD_VERSION["eimos"].version = "PDT_20241220";
        window.BUI_BUILD_VERSION["eimos"].date = "2025-3-19 11:10:55";
      } catch (e) {
        console.error('BuiBuildVersion error');
        console.error(e);
      }
    